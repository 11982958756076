<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
      <router-outlet #outlet="outlet" (activate)="onActivate($event, outlet)"></router-outlet>
    }
  </div>

  <!-- Print the version number in small font in the bottom in a static position -->
  <div class="fixed bottom-0 right-0 z-50 p-2 text-xs text-secondary print:hidden">
    <span>13.5.2</span>
  </div>

</div>
